<template>
<vuestic-widget>
  <template slot="header">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <span class="title">Users</span>
      <div class="d-flex justify-content-end align-items-center">
        <router-link class="btn btn-primary btn-sm" :to="{name: 'admin.users.create'}">
          <span>New User</span>
        </router-link>
      </div>
    </div>
  </template>
  <div class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-center" v-if="userFetching">
        <span class="atom-spinner-wrapper">
          <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)"/>
        </span>
      </div>
      <datatable v-else v-bind="this.tableConfig" class="le-datatable"/>
    </div>
  </div>

  <vuestic-modal 
    :isOpen="isOpenModalDelete"
    :hideDefaultActions="true"
    @cancel="closeModalDelete"
    :cancelShown="false"
    :okShown="false"
  >
    <span slot="title" class="text-danger font-weight-bold">Delete User</span>
    <p>Are you sure you want to delete this user <strong>{{userSelected ? userSelected.name : ''}}</strong></p>
    <div class="row mt-5">
      <div class="col-md-12 d-flex justify-content-center">
        <button @click="onDeleteUser()" class="btn btn-danger" :disabled="userProcessing">
          <atom-spinner v-if="userProcessing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
          <span v-else>Delete</span>
        </button>
      </div>
    </div>
  </vuestic-modal>

  <vuestic-modal
    :isOpen="isOpenModalPassword"
    @cancel="onCloseModalPassword"
    :cancelShown="false"
    :okShown="false"
  >
    <span slot="title">Update Password</span>

    <ValidationObserver 
      v-slot="{ handleSubmit, invalid }"
      ref="passwordForm"
    >
      <form @submit.prevent="handleSubmit(handleUpdatePassword)">
        <div class="row">
          <div class="col-md-6">
            <text-input name="Password" v-model="passwordData.password" label="New Password"
              validate="required" ref="new_password" type="password"/>
          </div>
          <div class="col-md-6">
            <text-input name="Confirm Password" v-model="passwordData.password_confirmation" label="Confirm password"
              :validate="`required|password:${passwordData.password}`" type="password"/>
          </div>
          <div class="col-md-12">
            <div class="control-label my-2">Send Email For Login Info</div>
            <vuestic-switch v-model="passwordData.send_email" :offcolor=true>
              <span slot="trueTitle">ON</span>
              <span slot="falseTitle">OFF</span>
            </vuestic-switch>
          </div>
        </div>
        <div class="mt-4 d-flex justify-content-center">
          <button class="btn btn-primary" :disabled="loadingUpdatePassword || invalid">
            <atom-spinner v-if="loadingUpdatePassword" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
            <span v-else>Update</span>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-modal>

  <vuestic-modal
    :isOpen="isOpenModalReset"
    @ok="handleResetBusiness"
    @cancel="closeResetPasswordModal"
    okText="Send Link"
    cancelText="Cancel"
  >
    <span slot="title" class="text-primary"><b>Sent New Password</b></span>

    <div>Confirm you want to send new password to the business <b>{{userSelected ? userSelected.name : ''}}</b> ?</div>
  </vuestic-modal>
</vuestic-widget>
</template>

<script>
  import Vue from 'vue';
  import Action from './Actions/Action'
  import { mapState } from 'vuex';
  import moment from 'moment'
  import components from "../../common/tables/comps";

  export default {
    components: { 
    },
    data() {
      return {
        isOpenModalPassword: false, 
        isOpenModalReset: false,
        userSelected: {
        },
        loadingUpdatePassword: false,
        loading: false,
        isOpenModalDelete: false,
        tableConfig: {
          supportBackup: false,
          supportNested: false,
          HeaderSettings: false,
          tblClass: "table-bordered",
          tblStyle: "color: #666",
          pageSizeOptions: [10, 25, 50, 100],
          columns: (() => {
            return [
              { title: "ID", field: "id", label: "ID", tdClass: "center", sortable: true,  visible: false },
              { title: "Name", field: "name", label: "Name", thClass: "left", tdClass: "left", sortable: true,  visible: true },
              { title: "Email",  field: "email", label: "Email",  thClass: "left",  tdClass: "left",  sortable: true, visible: true },
              { title: "CreatedAt",  field: "created_at",  label: "CreatedAt", tdClass: "center", tdComp: "TdTimestamp", sortable: false, visible: true },
              { title: "Actions",  tdComp: Action, sortable: false, visible: true },
            ];
          })(),
          data: [],
          total: 0,
          // selection: [],  //if this is present the row selector shows up..
          summary: {},
          query: {},
          // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
          xprops: {
            eventbus: new Vue()
          }
        },
        submitDataOrg: {
          id: undefined,
          name: '',
          tags: [],
          scheduled_at: '',
          message: ''
        },
        submitData: {
          id: undefined,
          name: '',
          tags: [],
          send_at: 'now',
          scheduled_at: '',
          message: ''
        },
        passwordDataOrg: {
          id: undefined,
          password: '',
          password_confirmation: '',
          send_email: false
        },
        passwordData: {
          id: undefined,
          password: '',
          password_confirmation: '',
          send_email: false
        }
      };
    },

    created () {
      this.submitData = Vue.util.extend({}, this.submitDataOrg)

      this.tableConfig.xprops.eventbus.$on('onEdit', row => {
        this.$router.push({ name: 'admin.users.edit', params: {id: row.id} });
      })

      this.tableConfig.xprops.eventbus.$on('onUpdatePassword', row => {
        this.onUpdatePassword(row)
      })

      this.tableConfig.xprops.eventbus.$on('onSendPassword', row => {
        this.onSendPassword(row)
      })
      
      this.tableConfig.xprops.eventbus.$on('onDelete', row => {
        this.userSelected = row
        this.isOpenModalDelete = true
      })

      const offset = (this.userPage - 1 >= 0 ? this.userPage - 1 : 0) * this.userPerPage;
      this.tableConfig.query = {
        offset,
        limit: this.userPerPage,
      }
    },

    watch: {
      users: {
        handler: function(newValue, oldValue) {
          if (newValue != oldValue)
          {
            this.tableConfig.data = newValue;
          }
        },
        deep: true,
      },
      userTotal: {
        handler: function(newValue, oldValue) {
          if (newValue != oldValue)
          {
            this.tableConfig.total = newValue;
          }
        },
        deep: true,
      },
      'tableConfig.query': {
        handler() {
          this.loadUsers();
        },
        deep: true
      }
    },

    computed: {
      user () {
        return this.$store.state.auth.user
      },

      ...mapState('user', {
        userFetching: 'fetching',
        userDidFetch: 'didFetch',
        userProcessing: 'processing',
        userPage: 'page',
        userPerPage: 'perPage',
        userFilter: 'filter',
        users: 'data',
        userTotal: 'total'
      })
    },
    mounted() {
    },
    methods: {
      onUpdatePassword(row) {
        this.passwordData = {
          ...this.passwordDataOrg
        }
        this.passwordData.id = row.id
        this.isOpenModalPassword = true;
      },
      onCloseModalPassword()
      {
        this.passwordData.id = null
        this.isOpenModalPassword = false;
      },

      handleUpdatePassword() {
        this.loadingUpdatePassword = true;

        this.$store.dispatch('user/changePassword', { id: this.passwordData.id, data: this.passwordData })
          .then((res) => {
            this.loadingUpdatePassword = false
            this.$refs.passwordForm.reset()
            this.onCloseModalPassword();
          })
          .catch(err => {
            this.loadingUpdatePassword = false
            let errors = err.response.data.errors
            if (errors)
            {
              this.$refs.passwordForm.setErrors(errors)
            }
          })
      },

      onSendPassword(row) {
        this.userSelected = row
        this.isOpenModalReset = true;
      },

      closeResetPasswordModal() {
        this.isOpenModalReset = false;
        this.userSelected = undefined
      },

      handleResetBusiness() {
        this.$store.dispatch('user/resetPassword', this.userSelected.id)
          .then((res) => {
            this.closeResetPasswordModal()
          })
          .catch(err => {
            this.closeResetPasswordModal()
          })
      },

      loadUsers() {
        const { query } = this.tableConfig;
        const page = query.offset ? Math.floor(query.offset /  query.limit) + 1 : 1;
        const param = {
          page,
          per_page: query.limit,
          order: query.order,
          sort: query.sort
        }

        this.$store.dispatch('user/fetch', param)
      },

      closeModalDelete() {
        this.isOpenModalDelete = false
        this.userSelected = undefined
      },

      onDeleteUser() {

        this.$store
          .dispatch('user/delete', this.userSelected.id)
          .then(() => {
            this.isOpenModalDelete = false;
            this.loadUsers();
          })
          .catch((err) => {
          })
      },
    },

  };
</script>

<style lang="scss" scoped>
</style>
